<template>
  <div class="cloud-brain">
    <search-wrap class="search-box">
      <el-form inline @submit.prevent>
        <el-form-item>
          <el-input v-model="search.orgName" clearable placeholder="搜索厂家名称" />
        </el-form-item>
        <el-form-item>
          <el-button @click="onSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <div class="right">
        <el-button @click="$router.push({ name: 'CloudBrainDataModule' })">数据模块管理</el-button>
        <el-button @click="$router.push({ name: 'CloudBrainDataSource' })">数据元管理</el-button>
      </div>
    </search-wrap>
    <!-- 批量处理 -->
    <div class="batch">
      <el-button v-if="!batchFlag" size="mini" @click="batchFlag = true">批量操作</el-button>
      <el-button v-else size="mini" @click="batchFlag = false;multipleSelection = []">取消批量操作</el-button>
      <el-button
        v-if="batchFlag"
        size="mini"
        type="warning"
        @click="batchInit"
      >
        批量初始化
      </el-button>
    </div>
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      row-key="topOrgId"
      highlight-current-row
      @sort-change="onSortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="batchFlag"
        reserve-selection
        type="selection"
        align="center"
        width="55"
      />
      <el-table-column prop="topOrgName" label="企业名称" />
      <el-table-column prop="topOrgId" label="组织ID" />
      <el-table-column prop="moduleCount" width="120" label="选用模块 数量" />
      <el-table-column prop="unitCount" width="130" label="选用数据元 数量" />
      <el-table-column label="操作" align="center" width="160">
        <template slot-scope="scoped">
          <el-button type="warning" size="mini" @click="initData(scoped.row)"> 初始化 </el-button>
          <el-button type="primary" size="mini" @click="onCheck(scoped.row)"> 查看 </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'CloudBrain',
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      search: {
        orgName: ''
      },
      tableData: [],
      batchFlag: false,
      multipleSelection: []
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    initData(row) {
      this.$axios
        .get(this.$API.initialization, {
          params: {
            orgId: row.topOrgId,
            orgName: row.topOrgName
          }
        })
        .then(res => {
          console.log('res: ', res)
          this.$message.success('初始化成功')
          this.getDataList()
        })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    getDataList() {
      this.loading = true
      const params = this.getParams(this.search)
      this.$axios
        .get(this.$API.selectOrgModuleAndUnit, { params })
        .then(res => {
          this.tableData = res.data
          this.$nextTick(() => {
            console.log('this.multipleSelection: ', this.multipleSelection)
            this.toggleSelection(this.multipleSelection)
          })
          this.pagination.total = res.totalCount
        })
        .finally(() => {
          this.loading = false
        })
    },
    onCheck(row) {
      this.$router.push({
        name: 'CloudBrainFactoryDetail',
        query: {
          id: row.topOrgId
        }
      })
    },
    // 批量初始化
    batchInit() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请选择要初始化的数据')
        return
      }
      const params = this.multipleSelection.map(item => {
        return {
          orgId: item.topOrgId,
          orgName: item.topOrgName
        }
      })
      this.$axios
        .post(this.$API.initializationBatch, params)
        .then(res => {
          console.log('res: ', res)
          this.$message.success('初始化成功')
          this.getDataList()
          this.batchFlag = false
        })
    },
    // 切换选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          console.log('row: ', row)
          this.$refs.table.toggleRowSelection(row, true)
        })
      } else {
        this.$refs.table.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.cloud-brain {
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .batch {
    margin-bottom: 10px;
  }
  .right {
    margin-bottom: 22px;
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
