<template>
  <div class="cloud-brain">
    <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
      <span>{{ $route.meta.title }}</span>
      <el-button
        icon="el-icon-back"
        size="small"
        type="primary"
        @click="$router.back()"
      >
        返回
      </el-button>
    </div>
    <search-wrap class="search-box">
      <el-form inline>
        <el-form-item>
          <el-input v-model="search.title" clearable placeholder="搜索厂家名称" />
        </el-form-item>
        <el-form-item>
          <el-button @click="onSearch">搜索</el-button>
        </el-form-item>
      </el-form>
      <div class="right">
        <el-button @click="dialogVisible = true">新增数据元</el-button>
      </div>
    </search-wrap>
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="unitName" width="200" label="数据元名称" />
      <el-table-column prop="unitField" width="200" label="对应字段项" />
      <el-table-column prop="descripe" label="数据元解释" />
      <el-table-column prop="createdAt" width="160" label="创建时间" />
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scoped">
          <el-button
            size="mini"
            plain
            :disabled="scoped.row.isPublish"
            @click="onEdit(scoped.row)"
          >
            编辑
          </el-button>
          <el-button type="danger" size="mini" @click="onDel(scoped.row)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>

    <el-dialog
      :title="`${dialogForm.id ? '编辑' : '新增'}数据元指标`"
      :visible.sync="dialogVisible"
      width="500px"
      @close="onClose"
    >
      <div>
        <el-form
          ref="dialogForm"
          size="small"
          inline
          :model="dialogForm"
          label-suffix="："
          :rules="dialogFormRules"
        >
          <el-form-item label="数据元名称" prop="unitName">
            <el-input v-model="dialogForm.unitName" :maxlength="20" placeholder="请输入数据元名称" />
          </el-form-item>
          <el-form-item label="对应字段项" prop="unitField">
            <el-input
              v-model="dialogForm.unitField"
              :maxlength="20"
              :rows="3"
              placeholder="请输入对应字段项"
            />
          </el-form-item>
          <el-form-item label="数据元解释" prop="descripe">
            <el-input
              v-model="dialogForm.descripe"
              style="width: 300px;"
              type="textarea"
              :rows="3"
              placeholder="请输入数据元解释"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button v-loading="dialogLoading" type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'CloudBrain',
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      dialogVisible: false,
      search: {
        title: ''
      },
      tableData: [],
      dialogLoading: false,
      dialogForm: {},
      dialogFormRules: {
        'unitName': [{required: true, message: '请输入数据元名称', trigger: 'blur'}],
        'unitField': [{required: true, message: '请输入对应字段项', trigger: 'blur'}],
        'descripe': [{required: true, message: '请输入数据元解释', trigger: 'blur'}]
      }
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    getDataList() {
      const params = this.getParams(this.search)
      this.loading = true
      this.$axios.get(this.$API.selectUnitPage, {params}).then(res => {
        this.tableData = res.data
        this.pagination.total = res.totalCount
      }).finally(() => {
        this.loading = false
      })
    },
    onEdit(row) {
      this.dialogForm = {...row}
      this.dialogVisible =  true
    },
    onDel(row) {
      this.$confirm('确定删除该数据元指标吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get(this.$API.unitDetail, {params: {id: row.id}}).then(() => {
          this.$message.success('删除成功')
          this.getDataList()
        })
      })
    },
    onSubmit() {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          const params = {
            ...this.dialogForm
          }
          this.dialogLoading = true
          this.$axios.post(this.$API.saveOrUpdateUnit, params).then(() => {
            this.$message.success('操作成功')
            this.dialogVisible = false
            this.getDataList()
          }).finally(() => {
            this.dialogLoading = false
          })
          console.log(123)
        }
      })
    },
    onClose() {
      this.dialogVisible = false
      this.dialogForm = {}
      this.$refs.dialogForm.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.cloud-brain {
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right {
    margin-bottom: 22px;
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
