<template>
  <div class="cloud-brain">
    <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
      <span>{{ $route.meta.title }}</span>
      <el-button
        icon="el-icon-back"
        size="small"
        type="primary"
        @click="$router.back()"
      >
        返回
      </el-button>
    </div>
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
    >
      <el-table-column prop="cloudBrainModuleName" width="200" label="数据模块名称" />
      <el-table-column prop="unitCount" label="数据元数量" />
      <el-table-column prop="createAt" width="200" label="创建时间" />
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scoped">
          <el-button
            size="mini"
            plain
            :disabled="scoped.row.isPublish"
            @click="onEdit(scoped.row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-loading="dialogLoading"
      :title="`编辑数据模块`"
      :visible.sync="dialogVisible"
      width="500px"
      @close="onClose"
    >
      <div>
        <ElTableDraggable v-model="dateSourceDetail.unitVOList" handle=".el-icon-rank" item-key="cloudBrainUnitId">
          <el-table
            ref="multipleTable"
            height="400px"
            :data="dateSourceDetail.unitVOList"
            row-key="cloudBrainUnitId"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column width="40" align="center">
              <i class="el-icon-rank" />
            </el-table-column>
            <el-table-column prop="cloudBrainUnitName" label="数据元名称" />
            <el-table-column prop="cloudBrainUnitField" label="对应字段项" />
          </el-table>
        </ElTableDraggable>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button v-loading="dialogLoading" type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElTableDraggable from 'el-table-draggable'
export default {
  name: 'CloudBrain',
  components: {ElTableDraggable},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      tableData: [],
      dateSourceDetail: [],
      dialogLoading: false,
      multipleSelection: []
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    getDataList() {
      this.loading = true
      this.$axios
        .get(this.$API.selectModuleAndUnitCount)
        .then(res => {
          this.tableData = res.map(item => {
            item.createAt = this.$dayjs(item.createAt).format('YYYY-MM-DD HH:mm:ss')
            return item
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onEdit(row) {
      this.dialogVisible = true
      this.dialogLoading = true
      this.$axios.get(this.$API.editModuleAndUnitRelation, { params: { moduleId: row.cloudBrainModuleId } }).then(res => {
        this.dateSourceDetail = res
        this.$nextTick(() => {
          res.unitVOList.forEach(item => {
            if (item.isSelected === 1) {
              this.$refs.multipleTable.toggleRowSelection(item)
            }
          })
        })
      }).finally(() => {
        this.dialogLoading = false
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onSubmit() {
      // 帮我写一个排序方法 this.multipleSelection 根据 dateSourceDetail.unitVOList 排序
      let result = this.multipleSelection.sort((a, b) => {
        return this.dateSourceDetail.unitVOList.findIndex(item => item.cloudBrainUnitId === a.cloudBrainUnitId) - this.dateSourceDetail.unitVOList.findIndex(item => item.cloudBrainUnitId === b.cloudBrainUnitId)
      })
      const params = {
        cloudBrainModuleId: this.dateSourceDetail.cloudBrainModuleId,
        cloudBrainUnitIdList: result.map(item => item.cloudBrainUnitId)
      }
      this.dialogLoading = true
      this.$axios
        .post(this.$API.saveModuleAndUnitRelation, params)
        .then(() => {
          this.$message.success('操作成功')
          this.dialogVisible = false
          this.getDataList()
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    onClose() {
      this.dialogVisible = false
      this.dialogForm = {}
      this.$refs.dialogForm.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.cloud-brain {
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right {
    margin-bottom: 22px;
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
