<template>
  <div class="factory-detail">
    <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
      <span>{{ $route.meta.title }}</span>
      <el-button
        icon="el-icon-back"
        size="small"
        type="primary"
        @click="$router.back()"
      >
        返回
      </el-button>
    </div>
    <el-form label-suffix="：">
      <el-form-item label="企业名称">
        <el-tag type="info">{{ configForm.topOrgName }}</el-tag>
      </el-form-item>
      <el-form-item label="皮肤设置">
        <el-button type="text" @click="dialogVisible = true"> 更换皮肤 </el-button>
      </el-form-item>
    </el-form>

    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="数据模块" name="data-module">
        <ElTableDraggable v-model="configForm.cloudBrainOrgModuleVOPage.records" handle=".el-icon-rank" item-key="cloudBrainModuleId">
          <el-table
            ref="table"
            v-loading="loading"
            row-key="cloudBrainModuleId"
            class="list-table"
            :data="configForm.cloudBrainOrgModuleVOPage.records"
            border
            stripe
            highlight-current-row
            @sort-change="onSortChange"
          >
            <el-table-column width="40" align="center">
              <i class="el-icon-rank" />
            </el-table-column>
            <el-table-column prop="cloudBrainModuleId" label="模块ID" />
            <el-table-column prop="cloudBrainModuleName" label="模块名称" />
            <!-- <el-table-column prop="cloudBrainModuleName" label="模块数据元" /> -->
            <el-table-column
              prop="statusDesc"
              label="状态"
              align="center"
              width="100"
            >
              <template slot-scope="scoped">
                {{ scoped.row.status === 1 ? '启用' : '未启用' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scoped">
                <el-button
                  v-if="scoped.row.status === 2"
                  type="primary"
                  size="mini"
                  @click="changePublishStatus(scoped.row, 1)"
                >
                  开启模块
                </el-button>
                <el-button
                  v-else
                  type="warning"
                  size="mini"
                  @click="changePublishStatus(scoped.row, 2)"
                >
                  关闭模块
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="scoped.row.isPublish"
                  @click="onEdit(scoped.row)"
                >
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </ElTableDraggable>
        <div class="pagination-wrap">
          <el-pagination
            center
            :current-page="pagination.pageNo"
            :total="pagination.total"
            :page-size="pagination.pageSize"
            :page-sizes="pagination.sizes"
            :layout="pagination.layout"
            :hide-on-single-page="false"
            background
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          />
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      v-loading="themeConfigLoading"
      title="换皮肤设置"
      :visible.sync="dialogVisible"
      width="35%"
      @open="getConfig"
    >
      <el-form ref="themeConfig" :model="themeConfig" label-suffix="：">
        <el-form-item label="logo">
          <ImagesUpload
            :url.sync="themeConfig.specialConfig.logo"
            :width="100"
            :height="50"
            :image-width="110"
            :image-height="30"
            :headers="{ token: getToken() }"
            :action="API.imageUpload + '?bucketType=127'"
            :http-request="onHttpRequest"
            :max="1"
          />
        </el-form-item>
        <el-form-item label="色系风格">
          <el-color-picker v-model="themeConfig.specialConfig.themeColor" />
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center;" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSaveConfig">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑模块数据元" :visible.sync="organizeDialogVisible" width="700px">
      <ElTableDraggable v-model="moduleData.orgUnitVOList" handle=".el-icon-rank" item-key="cloudBrainUnitId">
        <el-table
          ref="multipleTable"
          v-loading="organizeLoading"
          border
          :data="moduleData.orgUnitVOList"
          height="400"
          row-key="cloudBrainUnitId"
          @selection-change="handleSelectionChange"
        >
          <el-table-column width="40" align="center">
            <i class="el-icon-rank" />
          </el-table-column>
          <el-table-column type="selection" align="center" width="55" />
          <el-table-column label="数据元名称" prop="cloudBrainUnitName" align="center" />
          <el-table-column label="对应字段项" prop="cloudBrainUnitField" align="center" />
        </el-table>
      </ElTableDraggable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="organizeDialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="organizeLoading" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import ElTableDraggable from 'el-table-draggable'
import API from '@/apis/api-types.js'
import { getToken } from '@/utils/auth.js'
export default {
  name: 'FactoryDetail',
  components: {
    ElTableDraggable
  },
  mixins: [paginationMixin],
  data() {
    return {
      API,
      getToken,
      loading: false,
      organizeLoading: false,
      activeName: 'data-module',
      dialogVisible: false,
      search: {
        orgId: this.$route.query.id
      },
      configForm: {
        topOrgName: '',
        cloudBrainOrgModuleVOPage: {
          records: [{}]
        }
      },
      themeConfigLoading: false,
      themeConfig: {
        id: this.$route.query.id,
        specialConfig: {
          logo: [],
          themeColor: ''
        }
      },
      organizeDialogVisible: false,
      organizeForm: {
        title: ''
      },
      moduleData: {},
      multipleSelection: []
    }
  },
  watch: {
    async 'configForm.cloudBrainOrgModuleVOPage.records'(newVal, oldVal) {
      if (newVal.length === oldVal.length) {
        try {
          await this.$axios.post(this.$API.setOrgModuleIndex, {
            orgId: this.search.orgId,
            orgModuleId: newVal.map(item => item.cloudBrainModuleId)
          })
          this.$message.success('排序成功')
        } catch (error) {
          this.configForm.cloudBrainOrgModuleVOPage.records = []
          this.getDataList()
        }
      }
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      const params = this.getParams(this.search)
      this.loading = true
      this.$axios
        .get(this.$API.selectOrgModule, { params })
        .then(res => {
          this.configForm = res
          this.pagination.total = res.cloudBrainOrgModuleVOPage.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    changePublishStatus(row, status) {
      let params = {
        orgId: this.search.orgId,
        orgModuleId: row.cloudBrainModuleId,
        status
      }
      this.$axios({
        method: 'post',
        url: this.$API.updateOrgModule,
        params
      }).then(res => {
        if (res) {
          this.$message.success('操作成功')
          this.getDataList()
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    onSubmit() {
      // 自定义排序函数
      const customSort = (a, b) => {
        const indexA = this.moduleData.orgUnitVOList.findIndex(item => item.cloudBrainUnitId === a.cloudBrainUnitId)
        const indexB = this.moduleData.orgUnitVOList.findIndex(item => item.cloudBrainUnitId === b.cloudBrainUnitId)
        return indexA - indexB
      }
      const params = {
        orgId: this.search.orgId,
        cloudBrainModuleId: this.moduleData.cloudBrainModuleId,
        cloudBrainUnitDTOS: this.multipleSelection.sort(customSort).map((item, index) => {
          return {
            orgUnitId: item.cloudBrainUnitId,
            orderIndex: index
          }
        })
      }
      this.organizeLoading = true
      this.$axios
        .post(this.$API.saveOrgModuleUnit, params)
        .then(() => {
          this.$message.success('操作成功')
          this.organizeDialogVisible = false
        })
        .finally(() => {
          this.organizeLoading = false
        })
    },
    onEdit(row) {
      this.organizeDialogVisible = true
      this.organizeLoading = true
      let params = {
        orgId: this.search.orgId,
        orgModuleId: row.cloudBrainModuleId
      }
      this.$axios
        .get(this.$API.editOrgModuleUnit, { params })
        .then(res => {
          this.moduleData = res

          this.$nextTick(() => {
            res.orgUnitVOList.forEach(item => {
              if (item.isSelected === 1) {
                console.log('item: ', item)
                this.$refs.multipleTable.toggleRowSelection(item)
              }
            })
          })
        })
        .finally(() => {
          this.organizeLoading = false
        })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onSaveConfig() {
      this.$refs.themeConfig.validate(valid => {
        if (valid) {
          const params = {
            topOrgId: this.$route.query.id,
            specialConfig: JSON.stringify(this.themeConfig.specialConfig)
          }
          this.$axios.post(this.$API.saveOrUpdateSpecial, params).then(() => {
            this.$message.success('操作成功')
            this.dialogVisible = false
          })
        }
      })
    },
    getConfig() {
      const params = {
        topOrgId: this.$route.query.id
      }
      this.themeConfigLoading = true
      this.$axios.get(this.$API.selectSpecialConfig, { params }).then(res => {
        res.specialConfig = JSON.parse(res.specialConfig)
        res.specialConfig.logo ?? (res.specialConfig.logo = [])
        this.themeConfig = res

      }).finally(() => {
        this.themeConfigLoading = false
      })
    },
    onHttpRequest(file) {
      const params = new FormData()
      params.append('image', file.file)
      this.$axios.post(file.action, params).then(({large}) => {
        // 替换第一个元素
        console.log('this.themeConfig.specialConfig.logo: ', this.themeConfig.specialConfig)
        this.themeConfig.specialConfig.logo.splice(0, 1, large)
        //  = large
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.factory-detail {
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
